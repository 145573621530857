export function get3s(opt) {
    return axios.post('/index/3s/get/', opt);
}

export function update3s(opt) {
    return axios.post('/index/3s/update/', opt);
}

export function getCard(opt) {
    return axios.post('/index/card/get/', opt);
}

export function updateCard(opt) {
    return axios.post('/index/card/update/', opt);
}

export function getIntro(opt) {
    return axios.post('/index/intro/get/', opt);
}

export function updateIntro(opt) {
    return axios.post('/index/intro/update/', opt);
}

export function getBanner(opt) {
    return axios.post('/index/slideshow/get/', opt);
}

export function addBanner(opt) {
    return axios.post('/index/slideshow/insert/', opt);
}

export function delBanner(opt) {
    return axios.post('/index/slideshow/delete/', opt);
}

export function login(opt) {
    return axios.post('/login/', opt);
}

export function logout(opt) {
    return axios.post('/logout/', opt);
}

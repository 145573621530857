<template>
  <div id="main">
    <div id="form">
      <el-row type="flex" justify="center">
        <el-col :span="8" style="color: white; font-weight: bold; font-size: 30px">系统登录</el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="8">
          <el-input type="text" size="large" v-model="form.username" prefix-icon="el-icon-user"></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="8">
          <el-input type="text" size="large" v-model="form.password" prefix-icon="el-icon-lock" show-password></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="8">
          <el-button size="large" type="primary" style="width: 100%;" @click="login">登录</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {login} from "@/plugins/api";

export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: '',
      }
    }
  },
  methods: {
    async login() {
      let res = await login(this.form)
      if (res['login'] == 'ok') {
        this.$router.push('/index')
      }
    }
  },
  mounted() {
    localStorage.clear()
  }
}
</script>

<style scoped lang="scss">
#main {background-color: #2c3e50; padding: 0}
.el-row {
  margin-bottom: 20px;
  &:last-child {
     margin-bottom: 0;
   }
}
#form {padding-top: 160px; width: 100%}
.el-input__inner {font-size: 24px}
</style>

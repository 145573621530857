<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  min-width: 1000px;
}
html, body {width: 100%;height: 100%;padding: 0; margin: 0; min-width: 1000px}
.box-card {margin: 10px 20px; flex: 1; min-height: 98%; height: auto}
.el-card__body {padding: 10px}
.el-link {
  margin-right: 5px;
  &:last-child {margin-right: 0}
}
</style>

"use strict";

import Vue from 'vue';
import axios from "axios";
import qs from 'query-string';
import store from '../store'
import {Message} from "element-ui";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 字符串的下划线格式转驼峰格式，eg：hello_world => helloWorld
function underline2Hump(s) {
  return s.replace(/_(\w)/g, (all, letter) => letter.toUpperCase());
}

// JSON对象的key值转换为驼峰式
function jsonToHump(obj) {
  if (obj instanceof Array) {
    obj.forEach((v, i) => {
      jsonToHump(v);
    });
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach((key) => {
      const newKey = underline2Hump(key);
      if (newKey !== key) {
        obj[newKey] = obj[key];
        delete obj[key];
      }
      jsonToHump(obj[newKey]);
    });
  }
}

let config = {
  baseURL: 'https://api.simpuretech.com/v1/',
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (!config.data) {
      config.data = {}
    }
    if (store.state.lang == 'zh') {
      config.data.language = 1
    } else {
      config.data.language = 2
    }
    if (!config.data.weight) config.data.weight = 10;

    config.data = qs.stringify(config.data)
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    jsonToHump(response.data.data)
    if (response.data.message) {
        if (response.data.state == -1) {
            Message.error(response.data.message)
        } else if (response.data.state == -2) {
            Message.error('请重新登录')
            localStorage.clear();
            location.href = '/#/login'
        } else {
            Message.success(response.data.message)
        }
    }
    return response.data.data;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;

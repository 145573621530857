<template>
  <div>
    <el-radio-group size="small" v-model="lang" @change="changeLang">
      <el-radio-button label="zh">中</el-radio-button>
      <el-radio-button label="en">En</el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'LangChanger',
  data() {
    return {
      lang: 'zh'
    };
  },
  methods: {
    changeLang() {
      this.$store.dispatch('changeLang')
    }
  },
  created() {
    this.lang = this.$store.state.lang
  }
};
</script>

<style scoped>

</style>

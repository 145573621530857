<template>
  <div id="header">
    <lang-changer></lang-changer>
    <span>管理员</span>
    <span id="out" @click="logout">退出</span>
  </div>
</template>

<script>
import LangChanger from "@/components/LangChanger";
import {logout} from "@/plugins/api";
export default {
  name: 'Header',
  components: {LangChanger},
  data() {
    return {}
  },
  methods: {
    async logout() {
      localStorage.clear()
      await logout()
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="scss">
#header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  z-index: 10;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  span {margin: 5px 10px}
  #out {
    cursor: pointer;
    &:hover {color: dodgerblue}
  }
}
</style>

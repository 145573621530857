<template>
  <div id="menu">
    <el-col :span="24">
      <el-row>
        <img src="../assets/logo.png" id="logo">
      </el-row>
      <el-menu
          :router="true"
          background-color="#304156"
          text-color="#c3cfde"
          active-text-color="#1890ff"
          class="el-menu-vertical">
        <el-submenu index="/index/banner">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span slot="title">首页</span>
          </template>
          <el-menu-item index="/index/banner">轮播图</el-menu-item>
          <el-menu-item index="/index/company">公司简介</el-menu-item>
          <el-menu-item index="/index/product">产品简介</el-menu-item>
        </el-submenu>
        <el-submenu index="/company">
          <template slot="title">
            <i class="el-icon-office-building"></i>
            <span slot="title">关于先普</span>
          </template>
          <el-menu-item index="/company/info">公司介绍</el-menu-item>
          <el-menu-item index="/company/process">发展历程</el-menu-item>
          <el-menu-item index="/company/honor">先普荣誉</el-menu-item>
        </el-submenu>
        <el-submenu index="/product">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span slot="title">产品中心</span>
          </template>
          <el-menu-item index="/product/spec">产品选型</el-menu-item>
          <el-menu-item index="/product/center">产品中心</el-menu-item>
        </el-submenu>
        <el-menu-item index="/application">
          <i class="el-icon-monitor"></i>
          <span slot="title">应用方案</span>
        </el-menu-item>
        <el-menu-item index="/news">
          <i class="el-icon-news"></i>
          <span slot="title">新闻中心</span>
        </el-menu-item>
        <el-submenu index="/other">
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span slot="title">其他</span>
          </template>
          <el-menu-item index="/other/banner">首图</el-menu-item>
<!--          <el-menu-item index="/other/link">友链</el-menu-item>-->
        </el-submenu>
      </el-menu>
    </el-col>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.el-menu-vertical {height: 100%}
#menu {border-right: 1px solid #2c3e50; background-color: #304156}
#logo {
  width: 90%;
  margin: 50px 0;
}
.el-menu-item {display: flex; align-items: center}
.el-submenu .el-menu-item {min-width: unset}
</style>
<style>
.el-menu{border-right: none !important}
.el-submenu__title {display: flex; align-items: center}
</style>

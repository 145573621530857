import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [
      {
        path: '/index',
        redirect: '/index/banner'
      },
      {
        path: '/index/banner',
        component: () => import('../views/index/Banner.vue')
      },
      {
        path: '/index/product',
        component: () => import('../views/index/Product.vue')
      },
      {
        path: '/index/company',
        component: () => import('../views/index/Company.vue')
      },
      {
        path: '/company/info',
        component: () => import('../views/company/Intro.vue')
      },
      {
        path: '/company/process',
        component: () => import('../views/company/Process.vue')
      },
      {
        path: '/company/honor',
        component: () => import('../views/company/Honor.vue')
      },
      {
        path: '/product/center',
        component: () => import('../views/product/Center.vue')
      },
      {
        path: '/product/spec',
        component: () => import('../views/product/Spec.vue')
      },
      {
        path: '/product/detail/:id',
        component: () => import('../views/product/Detail.vue')
      },
      {
        path: '/application',
        component: () => import('../views/Application.vue')
      },
      {
        path: '/news',
        component: () => import('../views/News.vue')
      },
      {
        path: '/other/banner',
        component: () => import('../views/other/Banner.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <el-row class="home" :span="24" type="flex">
    <el-col :span="3">
      <Menu id="menu"></Menu>
    </el-col>
    <el-col :span="21" style="display: flex; flex-direction: column; overflow: auto">
      <Header id="header"></Header>
      <router-view v-if="show" id="main"></router-view>
    </el-col>
  </el-row>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu';
import Header from '@/components/Header'

export default {
  name: 'Home',
  components: { Header, Menu },
  data() {
    return {
      show: true,
    }
  },
  watch: {
    '$store.state.lang': function () {
      this.show = false;
      setInterval(() => {
        this.show = true
      }, 1)
    }
  }
}
</script>

<style>
.home {width: 100%; height: 100%;display: flex}
#menu {height: 100%; }
#main {width: 100%; height: 100%; flex: 1; display: flex;
  background-color: #f0f2f5; padding: 20px 0; align-items: flex-start; }
#header {position: sticky; top: 0; background-color: white}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'zh',
    editorKey: 'camcx4lrm0harzs3xwzz6nxzppdp504fg182dzruml1aov3c',
  },
  mutations: {
    changeLang(state) {
      if (state.lang == 'zh') {
        state.lang = 'en';
      } else {
        state.lang = 'zh';
      }
    }
  },
  actions: {
    changeLang(context) {
      context.commit('changeLang')
    }
  },
  modules: {
  }
})
